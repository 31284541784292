<template>
  <b-card-code title="Account">
    <validation-observer
      ref="employeeNoForm"
    >
      <validation-provider
        #default="{ errors }"
        name="Employee No."
        vid="employeeNo"
        rules="required"
      >
        <b-row>
          <b-col
            lg="4"
            class="mb-1"
          > <b-form-group
            label="Employee No."
            label-for="employeeNo"
          >

            <b-input-group>
              <b-form-input
                v-model="employeeNo"
                :readonly="isValid"
              />
              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  :disabled="isValid"
                  @click="find"
                >
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </b-col>
        </b-row>
      </validation-provider>
    </validation-observer>
    <b-row>
      <b-col>
        <div
          class="employee-task d-flex justify-content-between align-items-center"
        >
          <b-media no-body>
            <b-media-aside class="mr-75">
              <b-avatar
                rounded
                size="80"
                :src="hrmsAvatar+employee.image"
              />
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="mb-0">
                {{ employee.firstName }} {{ employee.lastName }}

              </h6>
              <small>{{ employee.employeeId }}</small>
            </b-media-body>
          </b-media>
          <div class="d-flex align-items-center">
            <small class="text-muted mr-75">{{ employee.duration }}</small>

          </div>
        </div>
      </b-col>
    </b-row>
    <br>
    <br>
    <validation-observer
      ref="userForm"
    >
      <b-row>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <!-- basic -->
          <b-form-group
            label="User Name"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              readonly
              :value="employee.firstName"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <!-- basic -->
          <b-form-group
            label="Position"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              readonly
              :value="employee.jobPositionName"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <!-- basic -->
          <b-form-group
            label="Email"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              readonly
              :value="employee.email"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <validation-provider
            #default="{ errors }"
            name="User Role"
            vid="userRole"
            rules="required"
          >
            <!-- with helper text -->
            <b-form-group
              label="User Role"
              label-for="helperInput"
            >
              <v-select
                v-model="userGroupId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionUserGroupId"
                label="text"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Status"
            label-for="readOnlyInput"
          >
            <b-form-checkbox
              v-model="isActive"
              value="true"
              class="custom-control-success"
            >
              Active
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

    </validation-observer>
    <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        @click="save"
      >
        Save
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        @click="goUserManagement"
      >
        Cancel
      </b-button>
    </div>
    <template #code>
      {{ codeButton }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, BMedia, BMediaAside, BAvatar, BMediaBody, BInputGroupAppend, BInputGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */

import vSelect from 'vue-select'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { codeButton } from './code'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      invalid: false,
      isValid: false,
      isActive: false,
      isDone: false,
      employeeNo: '',
      employee: {},
      userGroupId: '',
      codeButton,
      optionUserGroupId: [],
      hrmsAvatar: this.$hrmsAvatar,
      required,
      email,

    }
  },
  mounted() {
    useJwt.getUserGroups()
      .then(res => { this.optionUserGroupId = res.data.list })
  },
  methods: {
    goUserManagement() {
      this.$router.push({ name: 'user-management' })
    },
    showError(error) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Something went wrong',
          icon: 'CoffeeIcon',
          variant: 'danger',
          text: error,
        },
      })
    },
    find() {
      this.$refs.employeeNoForm.validate().then(success => {
        console.log(success)
        if (success) {
          useJwt.findUser(this.employeeNo)
            .then(res => {
              if (res.data.error != null) {
                this.showError(res.data.error)
              } else {
                this.employee = res.data.emp
                this.isValid = true
              }
            })
        }
      })
    },
    save() {
      this.$refs.userForm.validate().then(success => {
        if (success) {
          const currentUser = JSON.parse(localStorage.getItem('userData')).username
          useJwt.addUser(this.employeeNo, this.userGroupId.value, this.isActive, currentUser)
            .then(res => {
              if (res.data.error != null) {
                this.showError(res.data.error)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Add user successfully.',
                  },
                }).then(
                  this.$router.push({ name: 'user-management' }),
                )
              }
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

export const codeButton = `
<template>
  <b-row>
    <b-col
      lg="6"
      class="mb-1"
    >
      <!-- button on right -->
      <b-input-group>
        <b-form-input placeholder="Button on right" />
        <b-input-group-append>
          <b-button variant="outline-primary">
            GO
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col lg="6">
      <!-- button on both side -->
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="outline-primary">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-prepend>
        <b-form-input placeholder="Button on both side" />
        <b-input-group-append>
          <b-button variant="outline-primary">
            Search !
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
import {BInputGroup, BFormInput, BInputGroupAppend, BInputGroupPrepend, BRow, BCol, BButton} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
  },
}
</script>
`

export const codeColumnSearch = `
<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email',
          },
        },
        {
          label: 'Date',
          field: 'startDate',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date',
          },
        },
        {
          label: 'Salary',
          field: 'salary',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Salary',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data })
  },
}
</script>
`

export const codeBasicGoodTable = `
<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'fullName',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Date',
          field: 'startDate',
        },
        {
          label: 'Salary',
          field: 'salary',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data })
  },
}
</script>
`

export const codeKitchenSink = `
<template>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'Id',
        },
        {
          key: 'avatar', label: 'Avatar',
        },
        { key: 'full_name', label: 'Full Name', sortable: true },
        { key: 'post', label: 'Post', sortable: true },
        'email',
        'city',
        'start_date',
        'salary',
        'age',
        'experience',
        { key: 'status', label: 'Status', sortable: true },
      ],
      items: [
        {
          id: 1,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/10-small.png'),
          full_name: "Korrie O'Crevy",
          post: 'Nuclear Power Engineer',
          email: 'kocrevy0@thetimes.co.uk',
          city: 'Krasnosilka',
          start_date: '09/23/2016',
          salary: '$23896.35',
          age: '61',
          experience: '1 Year',
          status: 2,
        },
        {
          id: 2,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/1-small.png'),
          full_name: 'Bailie Coulman',
          post: 'VP Quality Control',
          email: 'bcoulman1@yolasite.com',
          city: 'Hinigaran',
          start_date: '05/20/2018',
          salary: '$13633.69',
          age: '63',
          experience: '3 Years',
          status: 2,
        },
        {
          id: 3,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/9-small.png'),
          full_name: 'Stella Ganderton',
          post: 'Operator',
          email: 'sganderton2@tuttocitta.it',
          city: 'Golcowa',
          start_date: '03/24/2018',
          salary: '$13076.28',
          age: '66',
          experience: '6 Years',
          status: 5,
        },
        {
          id: 4,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/3-small.png'),
          full_name: 'Dorolice Crossman',
          post: 'Cost Accountant',
          email: 'dcrossman3@google.co.jp',
          city: 'Paquera',
          start_date: '12/03/2017',
          salary: '$12336.17',
          age: '22',
          experience: '2 Years',
          status: 2,
        },
        {
          id: 5,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/4-small.png'),
          full_name: 'Harmonia Nisius',
          post: 'Senior Cost Accountant',
          email: 'hnisius4@gnu.org',
          city: 'Lucan',
          start_date: '08/25/2017',
          salary: '$10909.52',
          age: '33',
          experience: '3 Years',
          status: 2,
        },
        {
          id: 6,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/5-small.png'),
          full_name: 'Genevra Honeywood',
          post: 'Geologist',
          email: 'ghoneywood5@narod.ru',
          city: 'Maofan',
          start_date: '06/01/2017',
          salary: '$17803.80',
          age: '61',
          experience: '1 Year',
          status: 1,
        },
        {
          id: 7,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/7-small.png'),
          full_name: 'Eileen Diehn',
          post: 'Environmental Specialist',
          email: 'ediehn6@163.com',
          city: 'Lampuyang',
          start_date: '10/15/2017',
          salary: '$18991.67',
          age: '59',
          experience: '9 Years',
          status: 3,
        },
        {
          id: 8,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/9-small.png'),
          full_name: 'Richardo Aldren',
          post: 'Senior Sales Associate',
          email: 'raldren7@mtv.com',
          city: 'Skoghall',
          start_date: '11/05/2016',
          salary: '$19230.13',
          age: '55',
          experience: '5 Years',
          status: 3,
        },
        {
          id: 9,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/2-small.png'),
          full_name: 'Allyson Moakler',
          post: 'Safety Technician',
          email: 'amoakler8@shareasale.com',
          city: 'Mogilany',
          start_date: '12/29/2018',
          salary: '$11677.32',
          age: '39',
          experience: '9 Years',
          status: 5,
        },
        {
          id: 10,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/6-small.png'),
          full_name: 'Merline Penhalewick',
          post: 'Junior Executive',
          email: 'mpenhalewick9@php.net',
          city: 'Kanuma',
          start_date: '04/19/2019',
          salary: '$15939.52',
          age: '23',
          experience: '3 Years',
          status: 2,
        },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = \`Row index: \${index}\`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
`

export const codeVueBasic = `
<template>
   <b-row>
    <!-- basic select -->
    <b-col md="6">
      <h6>Basic Select</h6>
      <b-card-text>
        <code>vue-select</code>
        <span> accepts arrays of primitive values or objects to use as </span>
        <code>options</code>
        <span> through the options prop:</span>
      </b-card-text>

      <b-form-group>
        <v-select
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="option"
        />
      </b-form-group>
    </b-col>

    <!-- select with icon -->
    <b-col md="6">
      <h6>Select with Icon</h6>
      <b-card-text>
        <span>The current option within the dropdown, contained within </span><code>&lt;li&gt;</code>
        <span>.</span>
        <code>option {Object}</code>
        <span> - The currently iterated option from </span>
        <code>filteredOptions</code>
      </b-card-text>

      <b-form-group>
        <v-select
          v-model="selected1"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="books"
          label="title"
        >
          <template #option="{ title, icon }">
            <feather-icon
              :icon="icon"
              size="16"
              class="align-middle mr-50"
            />
            <span> {{ title }}</span>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      selected: { title: 'Square' },
      selected1: {
        title: 'Aperture',
        icon: 'ApertureIcon',
      },
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
      books: [
        {
          title: 'Aperture',
          icon: 'ApertureIcon',
        },
        {
          title: 'Codepen',
          icon: 'CodepenIcon',
        },
        {
          title: 'Globe ',
          icon: 'GlobeIcon',
        },
        {
          title: 'Instagram ',
          icon: 'InstagramIcon',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
`

export const codeFloatinLabel = `
<template>
   <b-row>
    <b-col md="4">
      <div class="form-label-group">
        <b-form-input
          id="floating-label1"
          placeholder="Label Placeholder"
        />
        <label for="floating-label1">Label-placeholder</label>
      </div>
    </b-col>
    <b-col md="4">
      <div class="form-label-group has-icon-left">
        <b-form-input
          id="iconLabelLeft"
          placeholder="Icon Left, Default Input"
        />
        <div class="form-control-position">
          <i class="bx bx-user" />
        </div>
        <label for="iconLabelLeft">Icon Left, Default Input</label>
      </div>
    </b-col>
    <b-col md="4">
      <div class="form-label-group">
        <b-form-input
          placeholder="Disabled-placeholder"
          disabled
        />
        <label for="floating-label-disable">Disabled-placeholder</label>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BFormInput, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BRow,
    BCol,
  },
}
</script>
`

export const codeBasic = `
<template>
  <b-row>
    <b-col
      md="6"
      xl="4"
      class="mb-1"
    >

      <!-- basic -->
      <b-form-group
        label="Basic Input"
        label-for="basicInput"
      >
        <b-form-input
          id="basicInput"
          placeholder="Enter Email"
        />
      </b-form-group>
    </b-col>

    <b-col
      md="6"
      xl="4"
      class="mb-1"
    >
      <!-- input text with help -->
      <b-form-group>
        <label for="InputHelp">Input text with help</label>
        <small class="text-muted"> eg. <i>someone@example.com</i></small>
        <b-form-input id="InputHelp" />
      </b-form-group>
    </b-col>

    <b-col
      md="6"
      xl="4"
      class="mb-1"
    >
      <!-- disabled input -->
      <b-form-group
        label="Disabled Input"
        label-for="disabledInput"
      >
        <b-form-input
          id="disabledInput"
          disabled
        />
      </b-form-group>
    </b-col>

    <b-col
      md="6"
      xl="4"
    >
      <!-- with helper text -->
      <b-form-group
        label="With Helper Text"
        label-for="helperInput"
        description="Find helper text here for given textbox."
      >
        <b-form-input
          id="helperInput"
          placeholder="Name"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="6"
      xl="4"
    >
      <!-- readonly input -->
      <b-form-group
        label="Readonly Input"
        label-for="readOnlyInput"
      >
        <b-form-input
          id="readOnlyInput"
          value="You can't update me :P"
          readonly
        />
      </b-form-group>
    </b-col>

    <b-col
      md="6"
      xl="4"
    >
      <!-- static text -->
      <b-form-group
        label="Readonly Static Text"
        label-for="plainTextInput"
      >
        <b-form-input
          id="plainTextInput"
          plaintext
          value="email@pixinvent.com"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {BFormInput, BRow, BCol, BFormGroup} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },
}
</script>
`

export const codeDatalist = `
<template>
  <b-form-group class="mb-0">
    <b-form-input list="my-list-id" />

    <datalist id="my-list-id">
      <option>Manual Option</option>
      <option
        v-for="size in sizes"
        :key="size"
      >
        {{ size }}
      </option>
    </datalist>
  </b-form-group>
</template>

<script>
import { BFormInput, BFormGroup } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      sizes: ['Small', 'Medium', 'Large', 'Extra Large'],
    }
  },
}
</script>
`

export const codeDebounce = `
<template>
  <div>
    <b-form-input
      v-model="value"
      type="text"
      debounce="500"
    />
    <b-card-text class="mt-1 mb-0">
      <strong>Value : </strong><span>{{ value }}</span>
    </b-card-text>
  </div>
</template>

<script>
import { BFormInput, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BCardText,
  },
  data() {
    return {
      value: '',
    }
  },
}
</script>
`

export const codeSize = `
<template>
  <b-row>

    <!-- large -->
    <b-col cols="12">
      <b-form-group
        label="Large"
        label-for="largeInput"
      >
        <b-form-input
          id="largeInput"
          size="lg"
          placeholder="Large Input"
        />
      </b-form-group>
    </b-col>

    <!-- default -->
    <b-col cols="12">
      <b-form-group
        label="Default"
        label-for="defaultLabel"
      >
        <b-form-input
          id="defaultLabel"
          placeholder="Normal Input"
        />
      </b-form-group>
    </b-col>

    <!-- small -->
    <b-col cols="12">
      <b-form-group
        label="Small"
        label-for="smallInput"
      >
        <b-form-input
          id="smallInput"
          size="sm"
          placeholder="Small Input"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {BFormInput, BRow, BCol, BFormGroup} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },
}
</script>
`

export const codeStates = `
<template>
  <b-row>

    <!-- valid input -->
    <b-col md="6">
      <b-form-group
        label="Valid State"
        label-for="input-valid"
      >
        <b-form-input
          id="input-valid"
          :state="true"
          placeholder="Valid"
        />
        <b-form-valid-feedback>
          This is valid state.
        </b-form-valid-feedback>
      </b-form-group>
    </b-col>

    <!-- invalid input -->
    <b-col md="6">
      <b-form-group
        label="Invalid State"
        label-for="input-invalid"
      >
        <b-form-input
          id="input-invalid"
          :state="false"
          placeholder="Invalid"
        />
        <b-form-invalid-feedback>
          This is invalid state.
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormInput, BRow, BCol, BFormInvalidFeedback, BFormValidFeedback, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BRow,
    BFormGroup,
    BCol,
  },
}
</script>
`

export const codeFormatter = `
<template>
  <div>
    <b-form-group
      label="Text input with formatter (on input)"
      label-for="input-formatter"
      description="We will convert your name to lowercase instantly"
    >
      <b-form-input
        id="input-formatter"
        v-model="text1"
        placeholder="Enter your name"
        :formatter="formatter"
      />
    </b-form-group>
    <b-card-text><strong>Value:</strong> {{ text1 }}</b-card-text>

    <b-form-group
      label="Text input with lazy formatter (on blur)"
      label-for="input-lazy"
      description="This one is a little lazy!"
    >
      <b-form-input
        id="input-lazy"
        v-model="text2"
        placeholder="Enter your name"
        lazy-formatter
        :formatter="formatter"
      />
    </b-form-group>

    <b-card-text class="mb-0">
      <strong>Value:</strong> {{ text2 }}
    </b-card-text>
  </div>
</template>

<script>
import { BFormInput, BFormGroup, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BCardText,
    BFormGroup,
  },
  data() {
    return {
      text1: '',
      text2: '',
    }
  },
  methods: {
    formatter(value) {
      return value.toLowerCase()
    },
  },
}
</script>
`

export const codeValidateTooltips = `
<template>
  <b-form novalidate class="needs-validation" @submit.prevent>
    <b-form-row>

      <!-- valid input  -->
      <b-col
        md="4"
        class="mb-3"
      >
        <label for="input-valid1">Valid Input:</label>
        <b-form-input
          id="input-valid1"
          v-model="value1"
          :state="value1.length > 0"
          placeholder="Valid input"
        />
        <b-form-valid-feedback
          tooltip
        >
          Looks good!
        </b-form-valid-feedback>
        <b-form-invalid-feedback
          tooltip
        >
          Please provide a valid input.
        </b-form-invalid-feedback>
      </b-col>

      <!-- invalid input -->
      <b-col
        md="4"
        class="mb-3"
      >
        <label for="input-invalid1">Invalid Input:</label>
        <b-form-input
          id="input-invalid1"
          v-model="value2"
          :state="value2.length > 0"
          placeholder="Invalid input"
        />
        <b-form-valid-feedback tooltip>
          Looks good!
        </b-form-valid-feedback>
        <b-form-invalid-feedback
          tooltip
        >
          Please provide a valid input.
        </b-form-invalid-feedback>
      </b-col>

      <!-- invalid input -->
      <b-col
        md="4"
        class="mb-3"
      >
        <label for="input-invalid2">Invalid Input:</label>
        <b-form-input
          id="input-invalid2"
          v-model="value3"
          :state="value3.length > 0"
          placeholder="Invalid input"
        />
        <b-form-valid-feedback tooltip>
          Looks good!
        </b-form-valid-feedback>
        <b-form-invalid-feedback tooltip>
          Please provide a valid city.
        </b-form-invalid-feedback>
      </b-col>

      <!-- submit button -->
      <b-col
        cols="12"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
        >
          Submit
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { BFormInput, BCol, BForm, BFormRow, BFormValidFeedback, BFormInvalidFeedback, BButton} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormInput,
    BForm,
    BButton,
    BFormRow,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      value1: 'John',
      value2: 'Doe',
      value3: '',
    }
  },
}
</script>
`

export const codeFloatingLabelIcon = `
<template>
  <b-row>
    <b-col md="6">
      <div class="form-label-group">
        <b-form-input
          id="floating-label1"
          placeholder="Label Placeholder"
        />
        <label for="floating-label1">Label-placeholder</label>
      </div>
    </b-col>
    <b-col md="6">
      <div class="form-label-group">
        <b-form-input
          placeholder="Disabled-placeholder"
          disabled
        />
        <label for="floating-label-disable">Disabled-placeholder</label>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormInput, BRow, BCol
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
  },
}
</script>
`

export const codeHorizontalSize = `
<template>
  <div>
     <!-- large label  -->
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      label-size="lg"
      label="Large"
      label-for="input-lg"
    >
      <b-form-input
        id="input-lg"
        size="lg"
        placeholder="Large Input"
      />
    </b-form-group>

    <!-- default label -->
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      label="Default"
      label-for="input-default"
    >
      <b-form-input
        id="input-default"
        placeholder="Normal Input"
      />
    </b-form-group>

    <!-- small label -->
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      label-size="sm"
      label="Small"
      label-for="input-sm"
    >
      <b-form-input
        id="input-sm"
        size="sm"
        placeholder="Small Input"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
}
</script>
`
